// See: https://stackoverflow.com/questions/3916191/download-data-url-file/15832662#15832662
export function downloadUrl(url: string, filename: string) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
}