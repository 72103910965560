/**
 * Manages multiple modal instances identified my "id".
 */
export default class ModalListener {
    static dotNetHelper = {};

    static setDotNetHelper(id, value) {
        ModalListener.dotNetHelper[id] = value;
    }

    static async showDialog(id: string, methodName: string) {
        await ModalListener.dotNetHelper[id].invokeMethodAsync(methodName);
    }

    static async hideDialog(id, methodName) {
        await ModalListener.dotNetHelper[id].invokeMethodAsync(methodName);
    }

    static listen(id, showMethod, hideMethod) {
        const el = document.getElementById(id);

        el.addEventListener('show.bs.modal', function (event) {
            return ModalListener.showDialog(id, showMethod);
        });

        el.addEventListener('hidden.bs.modal', function (event) {
            return ModalListener.hideDialog(id, hideMethod);
        });
    }

    static dispose(id) {
        delete ModalListener.dotNetHelper[id];
    }
}