import Konva from "konva";
import {downloadUrl} from "./utils";

export default class KonvaManager {
    static stages: { [id: string]: Konva.Stage } = {};

    static init(id: string, width: number, height: number) {
        if (!KonvaManager.stages[id]) {
            KonvaManager.stages[id] = new Konva.Stage({
                container: id,
                width: width,
                height: height
            });
        }
    }

    static drawLayer(id: string, imageLinkOrDataURL: string, draggable: boolean = false, width: number | undefined, height: number | undefined) {
        const stage = KonvaManager.stages[id];
        const layer = new Konva.Layer();
        stage.add(layer);

        const h = height || stage.height();
        const w = width || stage.width();

        const image = new Image();
        image.onload = function () {
            const img = new Konva.Image({image: image, height: h, width: w, draggable: draggable});

            if (draggable) {
                img.x(stage.width() / 2 - img.width() / 2);
                img.y(stage.height() / 2 - img.height() / 2)

                const tr = new Konva.Transformer();
                tr.keepRatio(true);
                tr.resizeEnabled(draggable);
                tr.draggable(draggable);
                layer.add(tr);
                tr.nodes([img]);
            }

            layer.add(img);
        };
        image.src = imageLinkOrDataURL;
    }

    static save(id: string, filename: string) {
        const dataURL = KonvaManager.stages[id].toDataURL();
        alert(dataURL);
        downloadUrl(dataURL, filename);
    }
}