import * as ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-liquid';

export default class CodeEditor {
    static instances = {};
    static dotNetHelper = {};

    static enable(id: string, lang: string, helper: any) {
        CodeEditor.dotNetHelper[id] = helper;
        CodeEditor.instances[id] = ace.edit(id);
        
        //CodeEditor.instances[id].setFontSize(11);
        //CodeEditor.instances[id].setTheme("ace/theme/ambiance");
        
        CodeEditor.instances[id].session.setMode("ace/mode/" + lang);

        CodeEditor.instances[id].session.on('change', async function (delta) {
            await CodeEditor.dotNetHelper[id].invokeMethodAsync("ContentChanged", CodeEditor.instances[id].getValue());
        });
    }

    static dispose(id) {
        delete CodeEditor.instances[id];
        delete CodeEditor.dotNetHelper[id];
    }

    static getValue(id: string): any {
        //@ts-ignore
        return CodeEditor.updates[id];
    }
}