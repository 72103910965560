import CodeEditor from "./codeeditor";
import ModalListener from "./modallistener";
//@ts-ignore
import {ace} from "https://cdn.jsdelivr.net/npm/ace-builds@1.31.0/src-min-noconflict/ace.min.js";
import * as $ from "jquery";
import * as bootstrap from "bootstrap";
import KonvaManager from "./KonvaManager";

async function downloadFileFromStream(fileName: string, contentStreamReference: any) {
    const arrayBuffer = await contentStreamReference.arrayBuffer();
    const blob = new Blob([arrayBuffer]);
    const url = URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);
}

function hideModal(id: string) {
    const el = document.getElementById(id);
    let modal = bootstrap.Modal.getInstance(el);
    if (modal == null) {
        modal = new bootstrap.Modal(el);
    }
    modal.hide();
}

function showModal(id: string) {
    const el = document.getElementById(id);
    let modal = bootstrap.Modal.getInstance(el);
    if (modal == null) {
        modal = new bootstrap.Modal(el);
    }
    modal.show();
}

function clickElement(id: string) {
    document.getElementById(id).click();
}

export function GetTimezoneValue() {
    return new Date().getTimezoneOffset();
}

function changeUrl(url: string) {
    history.pushState(null, '', url);
}

function scrollTop() {
    document.documentElement.scrollTop = 0;
}

declare global {
    const CodeEditor: CodeEditor;
    const ModalListener: ModalListener;
    const KonvaManager: KonvaManager;
}
// ------------------------------------------------
// Access from .net via global variable
// ------------------------------------------------
//@ts-ignore
window.changeUrl = changeUrl;
//@ts-ignore
window.scrollTop = scrollTop;
//@ts-ignore
window.ModalListener = ModalListener;
//@ts-ignore
window.CodeEditor = CodeEditor;
//@ts-ignore
window.clickElement = clickElement;
//@ts-ignore
window.GetTimezoneValue = GetTimezoneValue;
//@ts-ignore
window.downloadFileFromStream = downloadFileFromStream;
//@ts-ignore
window.hideModal = hideModal;
//@ts-ignore
window.showModal = showModal;
//@ts-ignore
window.KonvaManager = KonvaManager;
// ------------------------------------------------